<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems">
      <template #action>
        <div class="search mr-2">
          <input
            type="text"
            v-model="form.search"
            class="form-control"
            :placeholder="$t('Search here.....')"
          />
        </div>
        <!-- <div class="sorting">
          <MultiSelectInput
            class="d-flex align-items-center m-0 gap-2"
            :label="'Sort by'"
            :placeholder="''"
          />
        </div> -->
      </template>
    </PageHeader>
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col lg="6">
            <div class="custom-search d-flex justify-content-between mb-1">
              <b-form-group class="m-0">
                <div class="d-flex align-items-center">
                  <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '25', '50', '100']"
                    class="ml-1"
                    @input="(value) => onPerPageChange({ pageLength: value })"
                  />
                </div>
              </b-form-group>
              <b-pagination
                :value="1"
                :total-rows="totalRecords"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => onPageChange({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
            <div class="api-keys-table">
              <!-- table -->
              <vue-good-table
                styleClass="vgt-table striped"
                :columns="columns"
                :fixed-header="false"
                :rows="rows"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm,
                  trigger: 'enter',
                }"
                :pagination-options="{
                  enabled: false,
                  perPage: pageLength,
                }"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  class="vgt-center-align vgt-text-disabled"
                  slot="emptystate"
                >
                  {{ $t("No data for table") }}
                </div>
                <template slot="table-row" slot-scope="props">
                  <!-- Column: Name -->
                  <div
                    v-if="props.column.field === 'check'"
                    class="d-flex align-items-center"
                  >
                    <div class="checkbox-group">
                      <input
                        type="checkbox"
                        class="checkbox-input"
                        :id="'check' + props.row.id"
                        :value="props.row.id"
                        @change="toggleSelection(props.row.id)"
                        :checked="selectedRows.includes(props.row.id)"
                      />
                      <label
                        :for="'check' + props.row.id"
                        class="checkbox-label"
                      ></label>
                    </div>
                  </div>
                  <span v-else-if="props.column.field === 'street'">
                    {{ props.row.street }}, {{ props.row.zipCode }}
                    {{ props.row.city }}, {{ props.row.country }}
                  </span>
                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div class="d-flex align-items-center justify-content-end mt-2">
              <button
                @click="deleteUnits"
                :disabled="selectedRows.length == 0"
                class="btn btn-secondary mr-1"
              >
                {{ $t("Delete Unit") }}
              </button>
              <button @click="storeUnit" class="btn btn-primary">
                {{ $t("Add Unit") }}
              </button>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="e-card">
              <div class="e-card-body">
                <b-row>
                  <b-col lg="12">
                    <TextInput
                      :label="$t('Company')"
                      :type="'text'"
                      v-model="unit.company"
                      :placeholder="''"
                    />
                  </b-col>
                  <b-col lg="12">
                    <TextInput
                      :label="$t('Organizational Unit')"
                      :type="'text'"
                      v-model="unit.unit"
                      :placeholder="''"
                    />
                  </b-col>
                  <b-col lg="12">
                    <TextInput
                      :label="$t('Street')"
                      :type="'text'"
                      v-model="unit.street"
                      :placeholder="''"
                    />
                  </b-col>
                  <b-col lg="12">
                    <TextInput
                      :label="$t('Additional Information')"
                      :type="'text'"
                      v-model="unit.additionalInformation"
                      :placeholder="''"
                    />
                  </b-col>
                  <b-col lg="12">
                    <div class="row">
                      <div class="col-3">
                        <TextInput
                          :label="$t('Zip Code')"
                          :type="'text'"
                          v-model="unit.zipCode"
                          :placeholder="''"
                        />
                      </div>
                      <div class="col-9">
                        <TextInput
                          :label="$t('City')"
                          :type="'text'"
                          v-model="unit.city"
                          :placeholder="''"
                        />
                      </div>
                    </div>
                  </b-col>
                  <b-col lg="6">
                    <div class="form-group">
                      <label
                        class="form-label input-label"
                        for="country"
                        >{{ $t("Country") }}</label
                      >
                      <multi-select
                        :multiple="false"
                        label="name"
                        track-by="name"
                        :options="countries"
                        v-model="unit.country"
                      />
                    </div>
                  </b-col>
                  <b-col lg="6">
                    <div class="form-group">
                      <label
                        class="form-label input-label"
                        for="address_for_invoices"
                        >{{ $t("Address for Invoices") }}</label
                      >
                      <multi-select
                        :multiple="false"
                        label="name"
                        track-by="name"
                        :options="[]"
                      />
                    </div>
                  </b-col>
                  <b-col lg="6">
                    <TextInput
                      :label="$t('Contact Person')"
                      :type="'text'"
                      :placeholder="''"
                      v-model="unit.contactPerson"
                    />
                  </b-col>
                  <b-col lg="6">
                    <TextInput
                      :label="$t('E-Mail Contact Person')"
                      :type="'text'"
                      v-model="unit.contactPersonEmail"
                      :placeholder="''"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import NotificationService from "../../services/notification.service";
import MultiSelect from "vue-multiselect";
import countries from "@/assets/data/countries.json";
import TextInput from "@/components/TextInput.vue";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
export default {
  components: {
    PageHeader,
    MultiSelect,
    TextInput,
    MultiSelectInput,
  },
  computed: {
    optionalItems() {
      return {
        createBtn1: {
          show: false,
          text: this.$t("Create Company"),
          icon: "PlusIcon",
          path: "/companies/create",
          permission: "companies.create",
        },
      };
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Units"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: "",
          field: "check",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Unit"),
          field: "unit",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Location"),
          field: "street",
        },
      ];
    },
  },
  data() {
    return {
      selectedRows: [], // Array to hold selected row IDs

      countries,
      totalRecords: 0,
      pageLength: 10,
      page: 1,
      form: {
        sortOrder: "",
        sortBy: "",
        search: "",
      },
      unit: {
        company: "",
        unit: "",
        street: "",
        additionalInformation: "",
        zipCode: "",
        city: "",
        country: null,
        contactPerson: "",
        contactPersonEmail: "",
      },
      rows: [],
      searchTerm: "",
    };
  },
  watch: {
    "form.search": function (newVal, oldVal) {
      this.loadItems();
    },
  },

  async created() {
    this.loadItems();
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    toggleSelection(rowId) {
      const index = this.selectedRows.indexOf(rowId);
      if (index > -1) {
        // If the ID is already selected, remove it
        this.selectedRows.splice(index, 1);
      } else {
        // If the ID is not selected, add it
        this.selectedRows.push(rowId);
      }
    },
    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      let response = await this.$store.dispatch("units/list", {
        page: this.page,
        ...this.form,
        perPage: this.pageLength,
      });
      this.rows = response?.data?.data;
      this.totalRecords = response?.data?.total;
    },
    async storeUnit() {
      await this.$store.dispatch("units/create", {
        ...this.unit,
        country: this.unit.country?.name ?? "",
      });
      this.resetForm();
      this.loadItems();
    },
    resetForm() {
      this.unit = {
        company: "",
        unit: "",
        street: "",
        additionalInformation: "",
        zipCode: "",
        city: "",
        country: null,
        contactPerson: "",
        contactPersonEmail: "",
      };
    },
    async deleteUnits() {
      this.$swal({
        title: this.$t("Do you want to delete these record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store
            .dispatch("units/deleteUnits", {
              ids: this.selectedRows,
            })
            .finally(() => {
              this.selectedRows = [];
              this.loadItems();
            });
        }
      });
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
